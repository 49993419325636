import React from 'react';
import styled from 'styled-components';

import UpcomingEvents from './compo/countdown';
import CTA from './compo/cta';
import HeroImageCarousel from './compo/hero_carousel';


import './home.css'

import localimage from './GBMPoster.jpeg'
import bookclub from './BookClub.jpeg'
import Birayni from './Biryani.jpeg'
import Bonfire from './bonfire.jpeg'
import qawwaliNight from './QN.jpeg'

import IftaarPoster from './Iftaar.jpeg'
import UrduWorkShop from "./UrduWorkshop.jpeg"
import SocialProfileV2 from './compo/social_stuff';
import AboutUs from './compo/aboutus';
import EventCountdown from './compo/nextevent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: #555;
  text-align: center;
  max-width: 600px;
  margin-bottom: 30px;
`;

const Button = styled.a`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;
const mockEvents = [
    { id: 4, name: "Urdu Workshop", date: "Biweekly" , time:"17:30",image:UrduWorkShop,description:"The Urdu Workshop by the Pakistani Student Association (PSA) at UTD offers students a bi-weekly chance to delve into the intricacies of the Urdu language. Through engaging activities like vocabulary building and conversational practice, participants, whether beginners or advanced learners, immerse themselves in Pakistani culture. Led by knowledgeable facilitators, the workshop provides not just language skills but also a deeper understanding of Pakistan's rich heritage."},
    { id: 1, name: "GBM", date: "Feb. 13, 2025", time:"18:30",image:localimage,description:"The Pakistan Student Association's general body meeting provides as a valuable opportunity for students to connect with the Pakistani community and immerse themselves in the culture. During these meetings, held every semester,  members discuss upcoming events, opportunities to represent the Pakistani community, and key initiatives of the PSA, allowing students to actively engage with and explore the rich Pakistani heritage. It is a platform for like-minded individuals to come together, share experiences, and build lasting friendships while promoting cultural awareness and unity. These gatherings not only strengthen bonds within the organization, however also create a welcoming space for all students interested in learning more about Pakistan and its diverse traditions."},
    { id: 2, name: "Bonfire", date: "Feb. 14, 2025", time:"19:30" ,image:Bonfire,description:"Gather around the warmth of the fire for a night of music, laughter, and storytelling! Enjoy traditional chai, roasted snacks, and great company as we celebrate Pakistani culture under the stars. A perfect way to unwind and connect with friends in a cozy and welcoming atmosphere."},
    { id: 3, name: "Iftaar", date: "Mar. 06, 2025", time:"17:30" ,image:IftaarPoster,description:"Join us for a heartfelt community Iftaar as we break our fast together with delicious traditional Pakistani food. Experience the spirit of Ramadan through unity, reflection, and shared blessings. Whether you're fasting or supporting, all are welcome to embrace the warmth of our culture and traditions."},
    { id: 5, name: "Poetry Night", date: "Apr 07, 2025" , time:"16:00", description:"Immerse yourself in the beauty of Urdu and English poetry at our soulful Poetry Night. Celebrate the art of expression with verses from classical and contemporary poets or share your own work. A night of deep emotions, meaningful conversations, and creative storytelling awaits!"},
    { id: 6, name: "Qawwali Night", date: "Apr 19, 2025" , time:"16:00", image:qawwaliNight, description:"Experience the magic of Sufi music at our electrifying Qawwali Night! Let the rhythmic beats and soulful melodies of live Qawwali transport you into a world of devotion and cultural richness. A night of spiritual elevation, powerful vocals, and an unforgettable celebration of Pakistani heritage!"},
    // ... add more events
  ];
  const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const nextEventDate = mockEvents.reduce((nextDate, event) => {
  var eventDateTime = event.date + " " + event.time;
  const eventDate = new Date(eventDateTime);
  return eventDate > new Date() && (!nextDate || eventDate < new Date(nextDate)) ? eventDateTime : nextDate;
}, null);

const nextEventName = mockEvents.reduce((nextDate, event) => {
  const eventDate = new Date(event.date);
  return eventDate > new Date() && (!nextDate || eventDate < new Date(nextDate))
    ? event.name // Use event name here
    : nextDate;
}, null);


const LandingPage = () => {
    return (
        <div class="App" >
          <HeroImageCarousel/>
          {nextEventDate && <EventCountdown nextEventDate={nextEventDate}   eventName={nextEventName}/>}
          <AboutUs/>
          <CenteredContainer>
            <SocialProfileV2/>
            
          </CenteredContainer>
          <CTA />
          
          <UpcomingEvents events={mockEvents} />
          
        </div>
    );
}

export default LandingPage;

